var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.verify
    ? _c("OneTimePassword")
    : _c(
        "div",
        { key: _vm.key, staticClass: "kt-form kt-form--label-right" },
        [
          _c("div", { staticClass: "kt-form__body" }, [
            _c("div", { staticClass: "kt-section kt-section--first" }, [
              _c(
                "div",
                { staticClass: "kt-section__body" },
                [
                  _c("div", { staticClass: "row p-3 pb-4" }, [
                    _c(
                      "h3",
                      {
                        staticClass:
                          "kt-section__title kt-section__title-sm w-100",
                      },
                      [
                        _vm._v(" 2 Factor Authentication "),
                        _c("span", { staticClass: "text-muted pull-right" }, [
                          _vm._v(_vm._s(_vm.user.userName)),
                        ]),
                      ]
                    ),
                    _c("span", { staticClass: "form-text text-muted pb-4" }, [
                      _vm._v(
                        " Two-factor authentication adds an additional layer of security to your account. "
                      ),
                    ]),
                  ]),
                  _c("SetupTwoFactorAuth", { attrs: { "full-screen": false } }),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "kt-portlet__foot" }, [
            _c("div", { staticClass: "row w-100" }, [
              _c("div", { staticClass: "offset-6 col-6" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-label-brand btn-bold pull-right",
                    on: { click: _vm.showAddNewMethodModal },
                  },
                  [
                    _c("i", { staticClass: "fa fa-plus" }),
                    _vm._v(" Add Authentication Method "),
                  ]
                ),
              ]),
            ]),
          ]),
          _c("TwoFactorAuthMethodModal", {
            ref: "two-factor-auth-method-modal",
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }