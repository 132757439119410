<template>
<OneTimePassword v-if="verify" />
<div
    v-else
    :key="key"
    class="kt-form kt-form--label-right"
>
    <div class="kt-form__body">
        <div class="kt-section kt-section--first">
            <div class="kt-section__body">
                <div class="row p-3 pb-4">
                    <h3 class="kt-section__title kt-section__title-sm w-100">
                        2 Factor Authentication <span class="text-muted pull-right">{{ user.userName }}</span>
                    </h3>
                    <span class="form-text text-muted pb-4">
                        Two-factor authentication adds an additional layer of security to your account.
                    </span>
                </div>

                <SetupTwoFactorAuth :full-screen="false" />
            </div>
        </div>
    </div>
    <div class="kt-portlet__foot">
        <div class="row w-100">
            <div class="offset-6 col-6">
                <button
                    class="btn btn-label-brand btn-bold pull-right"
                    @click="showAddNewMethodModal"
                >
                    <i
                        class="fa fa-plus"
                    />
                    Add Authentication Method
                </button>
            </div>
        </div>
    </div>
    <TwoFactorAuthMethodModal ref="two-factor-auth-method-modal" />
</div>
</template>

<script lang="ts">
import twoFactorMixins from '../../store/mixins/twoFactorMixins';
import OneTimePassword from '../../components/OneTimePassword/OneTimePassword.vue';
import SetupTwoFactorAuth from '../../components/SetupTwoFactorAuth.vue';
import TwoFactorAuthMethodModal from '../../components/TwoFactorAuthMethodModal.vue';

export default {
    name: 'AccountSecurity',
    components: {
        OneTimePassword,
        SetupTwoFactorAuth,
        TwoFactorAuthMethodModal,
    },
    mixins: [twoFactorMixins],
    data() {
        return {
            key: 0,
            verify: false,
        };
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        deviceType() {
            return this.$store.state.deviceType;
        },
        userTwoFactorAuthMethodId() {
            return this.$store.state.user.twoFactorAuth.userTwoFactorAuthMethodId;
        },
    },
    mounted() {
        const v = this;
        v.verify = false;
        v.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
            if (modalId == 'auth-method-modal') {
                v.key += 1;
            }
        });
    },
    methods: {
        showAddNewMethodModal() {
            this.$refs['two-factor-auth-method-modal'].open();
        },
        startChallengeForMethod(authMethod) {
            this.userTwoFactorAuthMethodId = authMethod.userTwoFactorAuthMethodId;
        },
    },
};
</script>

<style scoped>
div.invalid-feedback {
    padding-top: 4px;
}
</style>
